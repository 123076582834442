import axios from 'axios';
import store from '@/store/index';
import router from '@/router';
import _ from 'lodash';
import NotificationService from '@/services/notification.service';
import LoadingService from '@/services/loading.service';

export default function setup() {

  /**
   * AXIOS - Interceptor - RESPONSE
   */
  axios.interceptors.response.use(response => {
    LoadingService.hide();

    if (!_.isUndefined(response.data.message)) {
      let msg = '';

      if (_.isArray(response.data.message) || _.isObject(response.data.message)) {
        _.each(response.data.message, m => {
          msg += m + '<br>';
        });
      } else {
        msg = response.data.message;
      }

      NotificationService.show(msg);
    }

    return response.data;
  }, error => {
    let e = !_.isEmpty(error.data) && !_.isUndefined(error.data.error) ? error.data.error : 'Unknown error';
    let data = '';

    if (error.status === 422) {
      e = 'Validation Error!';
    }

    LoadingService.hide();

    switch(e) {
      case 'Unauthenticated':
        store.dispatch('reset');
        router.push('login');
        break;
      case 'Invalid Credentials!':
        NotificationService.show(e);
        break;
      case 'Validation Error!':
        if (_.isObject(error.data.error)) {
          data = `Validation Error!<br><br><ul>`;

          _.each(error.data.error, valError => {
            _.each(valError, m => {
              data += `<li>${m}</li>`;
            });
          });

          data += `</ul>`;
        }
        NotificationService.show(data);
        break;
      default:
        if (_.isObject(error.data.error)) {
          data = `<ul>`;

          _.each(error.data.error, m => {
            data += `<li>${m}</li>`;
          });

          data += `</ul>`;
        } else {
          data = e;
        }
        NotificationService.show(data);
        break;
    }
    return Promise.reject(e);
  });

  /**
   * AXIOS - Interceptor - REQUEST
   */
  axios.interceptors.request.use(config => {
    const token = store.getters['auth/token'];

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    const type = config.url.indexOf('/auth') > -1 ? 'circular' : 'linear';
    LoadingService.show(type);

    return config;
  }, error => {

    return Promise.reject(error);
  });
}

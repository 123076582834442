<template>
    <div class="login">
        <v-form class="login__form" @submit.prevent="login">
            <h1>{{ title }}</h1>
            <template v-if="withCredentials">
                <v-container>
                    <v-row>
                        <v-text-field
                            v-model="email"
                            :rules="[rules.required, rules.email]"
                            label="E-mail"
                            solo
                        >
                        </v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            v-model="password"
                            :rules="[rules.required]"
                            :type="'Password'"
                            label="Password"
                            solo
                        >
                        </v-text-field>
                    </v-row>
                </v-container>
                <v-row>
                    <v-btn class="ma-2" outlined type="submit">Login</v-btn>
                    <forgot-password />
                </v-row>
            </template>
            <template v-else>
                <v-btn :href="url" class="ma-2" outlined
                    >Login with Google
                </v-btn>
            </template>
        </v-form>
    </div>
</template>

<script>
import ForgotPassword from '@/views/login/components/ForgotPassword';
import NotificationService from '@/services/notification.service';
import Constants from '@/constants';

export default {
    name: 'Login',

    data: vm => ({
        email: '',
        password: '',
        title: Constants.APP_TITLE,
        rules: {
            required: value => !!value || 'Required',
            counter: value => value.length <= 20 || 'Max 20 characters',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail';
            }
        },
        url: Constants.APP_URL + '/api/v1/i/auth/google/login',
        withCredentials:
            typeof vm.$route.query.withCredentials !== 'undefined'
                ? JSON.parse(vm.$route.query.withCredentials)
                : false
    }),

    mounted() {
        console.log('http://localhost:8002/#/login?withCredentials=true');
        if (typeof this.$route.query.token !== 'undefined') {
            this.setToken(this.$route.query.token);
        }
        if (typeof this.$route.query.error !== 'undefined') {
            NotificationService.show(this.$route.query.error);
        }
    },

    methods: {
        login() {
            this.$store
                .dispatch('auth/login', {
                    email: this.email,
                    password: this.password
                })
                .then(() => {
                    this.getUserData();
                });
        },

        setToken(token) {
            this.$store.dispatch('auth/setToken', token).then(() => {
                this.getUserData();
            });
        },

        getUserData() {
            this.$store.dispatch('auth/getUserData').then(() => {
                this.$router.push('/dashboard');
            });
        }
    },

    components: {
        ForgotPassword
    }
};
</script>

<style lang="scss" scoped>
.login {
    position: absolute;
    top: -48px;
    left: 0;
    width: 100%;
    height: calc(100% + 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(circle, #564490 0%, #464a84 48%);

    h1 {
        font-family: Satisfy, cursive;
        font-size: 48px;
        text-align: center;
        font-weight: 300;
    }

    &__form {
        margin: 0 auto;

        h3 {
            font-family: cursive;
            color: #f37ab7;
        }
    }
}
</style>

import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  data: {},
  articles: []
};

// Getters
const getters = {

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns current Feed Creator data
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns Feed Creator's articles
   * @param state
   * @returns {*}
   */
  articles: state => state.articles
};

// Actions
const actions = {

  /**
   * Returns requested Feed Creator
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  get({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.get('feedcreators/' + id)
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          commit('SET_FEEDCREATOR', response.data);
          Logger('success', 'Feed Creator').log('Feed creator Info retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Returns requested Feed Creator's articles
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getArticles({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.get('feedcreators/' + id + '/items')
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          commit('SET_ARTICLES', response.data);
          Logger('success', 'Feed Creator').log('Feed creator Articles retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Creates New Feed Creator
   * @param commit
   * @param feedcreator
   * @returns {Promise<unknown>}
   */
  new({commit}, feedcreator) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.post('feedcreators', feedcreator)
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('success', 'Feed Creator').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Updates Feed Creator
   * @param commit
   * @param feedcreator
   * @returns {Promise<unknown>}
   */
  update({commit}, feedcreator) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.put('feedcreators/' + feedcreator.id, feedcreator.data)
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('success', 'Feed Creator').log(response.message)();
          resolve({
            message: response.message,
            id: feedcreator.id
          });
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Creates New Feed Creator article
   * @param commit
   * @param feedcreator
   * @returns {Promise<unknown>}
   */
  newArticle({commit}, feedcreator) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.post('feedcreators/' + feedcreator.id + '/items', feedcreator.article)
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('success', 'Feed Creator').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Feed Creator article
   * @param commit
   * @param feedcreator
   * @returns {Promise<unknown>}
   */
  deleteArticle({commit}, feedcreator) {

    return new Promise((resolve, reject) => {
      commit('FEEDCREATOR_REQUEST', true);

      Rest.delete('feedcreators/' + feedcreator.id + '/items/' + feedcreator.articleId)
        .then(response => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('success', 'Feed Creator').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('FEEDCREATOR_REQUEST', false);
          Logger('error', 'Feed Creator').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

// mutations
const mutations = {

  FEEDCREATOR_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_FEEDCREATOR(state, feedcreator) {
    state.data = feedcreator;
  },

  SET_ARTICLES(state, articles) {
    state.articles = articles;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
    state.articles = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  data: {},
  results: {},
  answers: []
};

// Getters
const getters = {

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns current poll data
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns current poll results
   * @param state
   * @returns {*}
   */
  results: state => state.results,

  /**
   * Returns poll answers
   * @param state
   * @returns {*}
   */
  answers: state => state.answers
};

// Actions
const actions = {

  /**
   * Returns requested poll
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  get({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.get('polls/' + id)
        .then(response => {
          commit('POLL_REQUEST', false);
          commit('SET_POLL', response.data);
          Logger('success', 'Poll').log('Poll Info retrieved')();
          resolve(response.data);
        }).catch(error => {
        commit('POLL_REQUEST', false);
        Logger('error', 'Poll').log(error)();
        reject(error);
      });
    });
  },

  /**
   * Creates New Poll
   * @param commit
   * @param sweepstake
   * @returns {Promise<unknown>}
   */
  new({commit}, sweepstake) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.post('polls', sweepstake)
        .then(response => {
          commit('POLL_REQUEST', false);
          Logger('success', 'Poll').log(response.message)();
          resolve(response.data);
        }).catch(error => {
        commit('POLL_REQUEST', false);
        Logger('error', 'Poll').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Updates Poll
   * @param commit
   * @param poll
   * @returns {Promise<unknown>}
   */
  update({commit}, poll) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.put('polls/' + poll.id, poll.data)
        .then(response => {
          commit('POLL_REQUEST', false);
          Logger('success', 'Poll').log(response.message)();
          resolve({
            message: response.message,
            id: poll.id
          });
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns requested poll's answers
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getAnswers({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.get('polls/' + id + '/answers')
        .then(response => {
          commit('POLL_REQUEST', false);
          commit('SET_ANSWERS', response.data);
          Logger('success', 'Poll').log('Answers retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Creates New Poll Answer
   * @param commit
   * @param poll
   * @returns {Promise<unknown>}
   */
  newAnswer({commit}, poll) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.post('polls/' + poll.id + '/answers', poll.answer)
        .then(response => {
          commit('POLL_REQUEST', false);
          Logger('success', 'Poll').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Poll Answer
   * @param commit
   * @param poll
   * @returns {Promise<unknown>}
   */
  deleteAnswer({commit}, poll) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.delete('polls/' + poll.id + '/answers/' + poll.answerId)
        .then(response => {
          commit('POLL_REQUEST', false);
          Logger('success', 'Poll').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns requested poll's results
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getResults({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.getExternal('poll/' + id + '/responses/true')
        .then(response => {
          commit('POLL_REQUEST', false);
          commit('SET_RESULTS', response.data);
          Logger('success', 'Poll').log('Results retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Requests clear all answers
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  clearPoll({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('POLL_REQUEST', true);

      Rest.delete('polls/' + id + '/responses')
        .then(response => {
          commit('POLL_REQUEST', false);
          Logger('success', 'Poll').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('POLL_REQUEST', false);
          Logger('error', 'Poll').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

// mutations
const mutations = {

  POLL_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_POLL(state, poll) {
    state.data = poll;
  },

  SET_ANSWERS(state, answers) {
    state.answers = answers;
  },

  SET_RESULTS(state, results) {
    state.results = results;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
    state.results = {};
    state.answers = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({

  modules,

  actions: {

    /**
     * Resets state of all the modules
     * @param commit
     */
    reset({commit}) {

      return new Promise(resolve => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('u');
        delete axios.defaults.headers.common['Authorization'];

        Object.keys(modules).forEach(moduleName => {
          commit(`${moduleName}/RESET`);
        });

        resolve();
      });
    }
  }

});

<template>
    <v-navigation-drawer
        v-model="toggle"
        app
        clipped
        @transitionend="onTransition($event)"
    >
        <v-list dense>
            <v-list-item link to="/">
                <v-list-item-action>
                    <v-icon>fas fa-tachometer-alt</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/users" v-if="isAdmin">
                <v-list-item-action>
                    <v-icon>fas fa-users</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Users</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- begin: Debug & Purge Routes -->
            <template v-if="isAdmin || isDeveloper">
                <f-purge></f-purge>

                <v-list-item @click="to('mail')">
                    <v-list-item-action>
                        <v-icon>fas fa-envelope</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Test Mail</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('jobs')">
                    <v-list-item-action>
                        <v-icon>fas fa-truck</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Jobs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('failed-jobs')">
                    <v-list-item-action>
                        <v-icon>fas fa-truck-loading</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Failed Jobs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('routes')">
                    <v-list-item-action>
                        <v-icon>fas fa-link</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Available Routes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('logs/today')">
                    <v-list-item-action>
                        <v-icon>far fa-file-alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Today Logs</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('phpinfo')">
                    <v-list-item-action>
                        <v-icon>fab fa-php</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>PHPInfo</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item @click="to('telescope')">
                    <v-list-item-action>
                        <v-icon>fas fa-bug</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Telescope</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <!-- end: Debug Routes -->

            <v-list-item @click="logout">
                <v-list-item-action>
                    <v-icon>fas fa-sign-out-alt</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import Constants from '@/constants';
import Purge from '@/components/Purge.vue';

export default {
    name: 'Navigation',

    props: ['drawer'],

    data: () => ({
        toggle: false,
        baseURL: Constants.APP_URL
    }),

    computed: {
        isAdmin() {
            const user = this.$store.getters['auth/user'];

            return user ? user.role === 'Administrator' : false;
        },

        isDeveloper() {
            const user = this.$store.getters['auth/user'];

            return user ? user.role === 'Developer' : false;
        },

        token() {
            return this.$store.getters['auth/token'];
        }
    },

    watch: {
        drawer: {
            immediate: true,
            handler(val) {
                this.toggle = val;
            }
        }
    },

    methods: {
        to(endpoint) {
            let url =
                this.baseURL + '/api/dev/' + endpoint + '?token=' + this.token;

            if (endpoint === 'telescope') {
                url = this.baseURL + '/telescope?token=' + this.token;
            }

            window.open(url, '_blank');
        },

        logout() {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push('/login');
            });
        },

        onTransition() {
            this.$emit('drawerToggled', this.toggle);
        }
    },

    components: {
        FPurge: Purge
    }
};
</script>

<template>
    <v-app-bar
        app
        clipped-left
        fixed
    >
        <v-app-bar-nav-icon @click.stop="toggleDrawer"/>
        <v-toolbar-title>
            <router-link to="/">{{ title }}</router-link>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <f-user-profile></f-user-profile>
    </v-app-bar>
</template>

<script>
    import UserProfile from '@/components/UserProfile.vue';

    export default {

        name: 'Header',

        data: () => ({
            title: process.env.VUE_APP_TITLE
        }),

        methods: {

            toggleDrawer() {
                this.$emit('toggleDrawer', true);
            }
        },

        components: {
            fUserProfile: UserProfile
        }
    };
</script>

<style lang="scss" scoped>
    .v-toolbar__title a{
        color: #FFF;
        font-family: Satisfy, cursive;
        cursor: pointer;
        text-decoration: none;
        font-size: 24px;
    }
</style>

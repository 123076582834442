import Rest from '@/services/rest.service';
import _ from 'lodash';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  paginator: {
    current_page: 0,
    total_page: 0
  },
  data: [],
  searchedData: []
};

// Getters
const getters = {

  /**
   * Returns all users
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns searched data
   * @param state
   * @returns {any}
   */
  searchedData: state => state.searchedData,

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns pagination
   * @param state
   * @returns {*}
   */
  pagination: state => state.pagination
};

/**
 * Returns boolean to know if we can get
 * more pages
 * @param paginator
 * @returns {boolean}
 * @private
 */
function _canGetMore(paginator) {

  return (paginator.current_page < paginator.total_pages);
}

// Actions
const actions = {

  /**
   * Returns paginated users
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   */
  get({commit, state}) {

    return new Promise((resolve, reject) => {
      let nextPage = null;
      commit('USERS_REQUEST', true);

      if (_.isUndefined(state.paginator.next_page_url)) {
        nextPage = 1;
      } else if (!_.isNull(state.paginator.next_page_url)) {
        nextPage = state.paginator.current_page + 1;
      }

      if (nextPage === null) {
        commit('USERS_REQUEST', false);
        resolve(_canGetMore(state.paginator));
        return;
      }

      Rest.get('users?page=' + nextPage)
        .then(response => {
          commit('SET_PAGINATOR', response.paginator);
          commit('SET_USERS', response.data);
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log('Data retrieved')();
          resolve(_canGetMore(response.paginator));
        }).catch(error => {
          commit('USERS_REQUEST', false);
          Logger('error', 'Users').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Blocks user
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  block({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.put('users/' + id + '/block')
        .then(response => {
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log(response.message)();
          resolve({
            message: response.message,
            id
          });
        }).catch(error => {
          commit('USERS_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Unblocks user
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  unblock({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.put('users/' + id + '/unblock')
        .then(response => {
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log(response.message)();
          resolve({
            message: response.message,
            id
          });
        }).catch(error => {
          commit('USERS_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns single user
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  single({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.get('users/' + id)
        .then(response => {
          commit('PUT_USER', response.data);
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log('User:', response.data)();
          resolve(response.data);
        }).catch(error => {
          commit('USERS_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates User
   * @param commit
   * @param user
   * @returns {Promise<unknown>}
   */
  update({commit}, user) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.put('users/' + user.id, user.params)
        .then(response => {
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log('User:', response.message)();
          resolve(user);
        }).catch(error => {
        commit('USERS_REQUEST', false);
        Logger('error', 'Users').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Searches for a user
   * @param commit
   * @param keyword
   * @returns {Promise<unknown>}
   */
  search({commit}, keyword) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.get('users/search?keyword=' + keyword)
        .then(response => {
          commit('USERS_REQUEST', false);
          commit('SET_SEARCHED_USERS', response.data);
          Logger('success', 'Users').log('Search:', response.data)();
          resolve(response);
        }).catch(error => {
        commit('USERS_REQUEST', false);
        Logger('error', 'Users').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Creates New User
   * @param commit
   * @param user
   * @returns {Promise<unknown>}
   */
  new({commit}, user) {

    return new Promise((resolve, reject) => {
      commit('USERS_REQUEST', true);

      Rest.post('users', user)
        .then(response => {
          commit('USERS_REQUEST', false);
          Logger('success', 'Users').log(response.message)();
          resolve(response);
        }).catch(error => {
          commit('USERS_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error);
        });
    });
  }
};

// mutations
const mutations = {

  USERS_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_PAGINATOR(state, paginator) {
    state.paginator = paginator;
  },

  SET_USERS(state, users) {
    _.each(users, user => {
      state.data.push(user);
    });
  },

  SET_SEARCHED_USERS(state, users) {
    state.searchedData = users;
  },

  PUT_USER(state, user) {
    if (state.data.length > 0) {
      const index = _.findIndex(state.data, {id: user.id});

      if (index !== -1) {
        state.data[index] = Object.assign(state.data[index], user);
      }
    } else if (state.searchedData.length > 0) {
      const index = _.findIndex(state.searchedData, {id: user.id});

      if (index !== -1) {
        state.searchedData[index] = Object.assign(state.searchedData[index], user);
      }
    }
  },

  RESET(state) {
    state.status = '';
    state.paginator = {
      current_page: 0,
      total_page: 0
    };
    state.data = [];
    state.searchedData = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

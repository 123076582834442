import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  data: {}
};

// Getters
const getters = {

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns current locator data.
   * @param state
   * @returns {*}
   */
  data: state => state.data
};

// Actions
const actions = {

  /**
   * Returns requested poll
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  get({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('LOCATOR_REQUEST', true);

      Rest.get('locators/' + id)
        .then(response => {
          commit('LOCATOR_REQUEST', false);
          commit('SET_LOCATOR', response.data);
          Logger('success', 'Locator').log('Locator Info retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('LOCATOR_REQUEST', false);
          Logger('error', 'Locator').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Creates new Locator converter
   * @param commit
   * @param locatorData
   */
  new({commit}, locatorData) {

    return new Promise((resolve, reject) => {
      commit('LOCATOR_REQUEST', true);

      Rest.postMultipart('locators', locatorData)
        .then(response => {
          commit('LOCATOR_REQUEST', false);
          Logger('success', 'Locator').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('LOCATOR_REQUEST', false);
          Logger('error', 'Locator').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates targeted locator converter
   * @param commit
   * @param locatorData
   */
  update({commit}, locatorData) {

    return new Promise((resolve, reject) => {
      commit('LOCATOR_REQUEST', true);

      Rest.postMultipart('locators/' + locatorData.id, locatorData.data)
        .then(response => {
          commit('LOCATOR_REQUEST', false);
          Logger('success', 'Locator').log(response.message)();
          resolve({
            message: response.message,
            id: locatorData.id
          });
        }).catch(error => {
          commit('LOCATOR_REQUEST', false);
          Logger('error', 'Locator').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

const mutations = {
  LOCATOR_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_LOCATOR(state, locator) {
    state.data = locator;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

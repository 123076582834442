import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/login/Login.vue';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/login/ResetPassword.vue'),
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('../views/users/Users.vue'),
    meta: {
      requiresAuth: true
    }
  },

  //
  // Creatives
  //

  // SWEEPSTAKES
  {
    path: '/sweepstakes/:id/:selectedTab?',
    name: 'sweepstakes',
    component: () => import('../views/creatives/sweepstakes/Sweepstake.vue'),
    meta: {
      requiresAuth: true,
      label: 'Sweepstake'
    }
  },

  // SOCIAL MOSAIC
  {
    path: '/socialmosaic/:id/:selectedTab?',
    component: () => import('../views/creatives/socialmosaic/SocialMosaic.vue'),
    name: 'socialmosaic',
    meta: {
      requiresAuth: true,
      label: 'Social Mosaic'
    }
  },

  // FEED CREATOR
  {
    path: '/feedcreator/:id/:selectedTab?',
    name: 'feedcreator',
    component: () => import('../views/creatives/feedcreator/FeedCreator.vue'),
    meta: {
      requiresAuth: true,
      label: 'Feed Creator'
    }
  },

  // LOCATOR CONVERTER
  {
    path: '/locatorconverter/:id/:selectedTab?',
    name: 'locatorconverter',
    component: () =>
      import('../views/creatives/locatorconverter/LocatorConverter'),
    meta: {
      requiresAuth: true,
      label: 'Locator Converter'
    }
  },

  // POLL
  {
    path: '/poll/:id/:selectedTab?',
    name: 'poll',
    component: () => import('../views/creatives/poll/Poll.vue'),
    meta: {
      requiresAuth: true,
      label: 'Poll'
    }
  },

  // MASS EMAIL
  {
    path: '/mass-email/:id/:selectedTab?',
    name: 'massemail',
    component: () => import('../views/creatives/massemail/MassEmail.vue'),
    meta: {
      requiresAuth: true,
      label: 'Mass Email'
    }
  },

  // TRACKER
  {
    path: '/tracker/:id/:selectedTab?',
    name: 'tracker',
    component: () => import('../views/creatives/tracker/Tracker.vue'),
    meta: {
      requiresAuth: true,
      label: 'Tracker'
    }
  },

  // STANDALONE
  {
    path: '/standalone/:id/:selectedTab?/:startDate?/:endDate?',
    name: 'standalone',
    component: () => import('../views/creatives/standalone/Standalone.vue'),
    meta: {
      requiresAuth: true,
      label: 'Standalone'
    }
  },

  {
    path: '*',
    redirect: '/login'
  },

  {
    path: '/',
    redirect: '/login'
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (
    store.getters['auth/token'] !== '' &&
    store.getters['auth/isTokenExpired']
  ) {
    store.dispatch('reset');
    next('/login');
    return;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLoggedIn']) {
      next();
      return;
    }

    next('/login');
  } else {
    if (store.getters['auth/isLoggedIn'] && to.name === 'login') {
      next('/dashboard');
      return;
    }

    next();
  }
});

export default router;

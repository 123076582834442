import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';
import _ from 'lodash';

// initial state
const state = {
  status: '',
  data: {},
  recipients: [],
  paginator: {}
};

// Getters
const getters = {
  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  states: state => state.status,

  /**
   * Returns current massemail data.
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns creative recipients data.
   * @param state
   * @returns {*}
   */
  recipients: state => state.recipients,

  /**
   * Returns recpients paginator object.
   * @param state
   * @returns {*}
   */
  paginator: state => state.paginator
};

/**
 * Returns boolean to know if we can get
 * more pages
 * @param paginator
 * @returns {boolean}
 * @private
 */
function _canGetMore(paginator) {
  return (paginator.current_page < paginator.total_pages);
}

// Actions
const actions = {
  /**
   * Creates new Mass email creative
   * @param commit
   * @param requestData
   */
  new({commit}, requestData) {
    return new Promise((resolve, reject) => {
      commit('MASSEMAIL_REQUEST', true);

      Rest.postMultipart('massemails', requestData)
        .then(response => {
          commit('MASSEMAIL_REQUEST', false);
          Logger('success', 'Massemail').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('MASSEMAIL_REQUEST', false);
          Logger('error', 'Massemail').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates desired massemail creative
   * @param commit
   * @param creative
   * @returns {Promise<unknown>}
   */
  update({commit}, creative) {
    commit('MASSEMAIL_REQUEST', true);

    return new Promise((resolve, reject) => {
      Rest.postMultipart('massemails/' + creative.id, creative.data).then((response) => {
        commit('MASSEMAIL_REQUEST', false);
        Logger('success', 'Massemail').log(response.message)();
        resolve({
          message: response.message,
          id: creative.id
        });
      }).catch(error => {
        commit('MASSEMAIL_REQUEST', false);
          Logger('error', 'Massemail').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Get recipients from massemail creative
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getRecipients({commit}, id) {
    commit('MASSEMAIL_REQUEST', true);

    return new Promise((resolve, reject) => {
      Rest.get('massemails/' + id + '/recipients').then(response => {
        commit('MASSEMAIL_REQUEST', false);
        commit('SET_RECIPIENTS', response.data);
        commit('SET_PAGINATOR', response.paginator);
        resolve();
      }).catch(error => {
        commit('MASSEMAIL_REQUEST', false);
        reject(error);
      });
    });
  },

  /**
   * Get more regsiters if possible
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<unknown>}
   */
  getMoreRecipients({commit, state}, id) {
    return new Promise((resolve, reject) => {
      let nextPage = null;
      commit('MASSEMAIL_REQUEST', true);

      if (_.isUndefined(state.paginator.next_page_url)) {
        nextPage = 1;
      } else if (!_.isNull(state.paginator.next_page_url)) {
        nextPage = state.paginator.current_page + 1;
      }

      if (nextPage === null) {
        commit('MASSEMAIL_REQUEST', false);
        resolve(_canGetMore(state.paginator));
        return;
      }

      Rest.get('massemails/' + id + '/recipients?page=' + nextPage)
        .then(response => {
          commit('MASSEMAIL_REQUEST', false);
          commit('SET_PAGINATOR', response.paginator);
          commit('SET_RECIPIENTS', response.data);
          Logger('success', 'Massemail').log('Entries Retrieved')();
          resolve(response);
        }).catch(error => {
          commit('MASSEMAIL_REQUEST', false);
          Logger('error', 'Massemail').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Get massemail creative
   * @param commit
   * @param id
   */
  get({commit}, id) {
    return new Promise((resolve, reject) => {
      commit('MASSEMAIL_REQUEST', true);

      Rest.get('massemails/' + id)
        .then(response => {
          commit('MASSEMAIL_REQUEST', false);
          commit('SET_MASSEMAIL', response.data);
          Logger('success', 'Massemail').log('Massemail info retrieved!')();
          resolve(response.data);
        }).catch(error => {
          commit('MASSEMAIL_REQUEST', false);
          Logger('error', 'Massemail').log('Error: ', error)();
          reject(error);
        });

    });
  },

  /**
   * Toggles delivered state of creative
   * @param commit
   * @param requestData
   * @returns {Promise<unknown>}
   */
  toggleStatus({commit}, requestData) {
    return new Promise((resolve, reject) => {
      commit('MASSEMAIL_REQUEST', true);

      Rest.patch('massemails/' + requestData.creativeID + '/delivered/' + requestData.status).then(() => {
        commit('UPDATE_DELIVERED_STATUS', requestData.status);
        resolve();
        Logger('success', 'Massemail').log('Delivered Toggled')();
      }).catch(error => {
        Logger('error', 'Massemail').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Executes mail sending
   * @param commit
   * @param requestData
   * @returns {Promise<unknown>}
   */
  sendMail({commit}, requestData) {
    return new Promise((resolve, reject) => {
      commit('MASSEMAIL_REQUEST', true);

      Rest.patch('massemails/' + requestData.creativeID + '/' + requestData.command, requestData.data).then(() => {
        if (requestData.command === 'execute') {
          commit('UPDATE_DELIVERED_STATUS', 1);
        }
        resolve();
        Logger('success', 'Massemail').log('Delivered Toggled')();
      }).catch(error => {
        Logger('error', 'Massemail').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Deletes specific recipient
   * @param commit
   * @param creative
   * @returns {Promise<unknown>}
   */
  deleteRecipient({commit}, params) {
    commit('MASSEMAIL_REQUEST', true);

    return new Promise((resolve, reject) => {
      Rest.delete('massemails/' + params.creativeID + '/recipients/' + params.recipientID).then(response => {
        commit('MASSEMAIL_REQUEST', false);
        commit('DELETE_RECIPIENT', params.recipientID);
        Logger('success', 'Massemail').log('Recipient deleted succesfully!')();
        resolve(response.data);
      }).catch(error => {
        commit('MASSEMAIL_REQUEST', false);
        Logger('error', 'Massemail').log(error)();
        reject(error);
      });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

const mutations = {

  MASSEMAIL_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_MASSEMAIL(state, creative) {
    state.data = creative;
  },

  SET_RECIPIENTS(state, recipients) {
    _.each(recipients, recipient => {
      recipient.selected = false;
      state.recipients.push(recipient);
    });
  },

  SET_PAGINATOR(state, paginator) {
    state.paginator = paginator;
  },

  UPDATE_DELIVERED_STATUS(state, newStatus) {
    state.data.delivered = newStatus;
  },

  DELETE_RECIPIENT(state, id) {
    const itemIndex = _.findIndex(state.recipients, (item) => {
      return item.id === id;
    });

    state.recipients.splice(itemIndex, 1);
    state.paginator.total--;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
    state.recipients = [];
    state.paginator = {
      current_page: 0,
      total_pages: 0
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

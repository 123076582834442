import axios from 'axios';
import Constants from '@/constants';

export default class RestService {
  /**
   * Rest Constructor
   * @param requestParams
   * @returns {AxiosPromise}
   */
  constructor(requestParams) {
    return axios(requestParams);
  }

  //
  // Internal Requests
  //

  /**
   * Executes GET Request
   * @param endpoint
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  static get(endpoint, params) {

    return axios({
      method: 'GET',
      url: Constants.APP_URL + '/api/v1/i/' + endpoint,
      params
    });
  }

  /**
   * Executes DELETE Request
   * @param endpoint
   * @returns {*}
   */
  static delete(endpoint) {

    return axios({
      method: 'DELETE',
      url: Constants.APP_URL + '/api/v1/i/' + endpoint,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }

  /**
   * Executes POST Request
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static post(endpoint, params) {

    return axios({
      method: 'POST',
      url: Constants.APP_URL + '/api/v1/i/' + endpoint,
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }

  /**
   * Executes POST request with multipart/form-data
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static postMultipart(endpoint, formData) {
    const url =  Constants.APP_URL + '/api/v1/i/' + endpoint
    return axios.post(url, formData);
  }

  /**
   * Executes PATCH Request
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static patch(endpoint, params) {

    return axios({
      method: 'PATCH',
      url: Constants.APP_URL + '/api/v1/i/' + endpoint,
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }

  /**
   * Executes PUT Request
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static put(endpoint, params) {

    return axios({
      method: 'PUT',
      url: Constants.APP_URL + '/api/v1/i/' + endpoint,
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }

  /**
   * Executes OPTIONS Request for External
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static optionsExternal(endpoint, params) {
    return axios({
      method: 'OPTIONS',
      url: Constants.APP_URL + '/api/v1/' + endpoint,
      params
    });
  }

  /**
   * Executes GET Request for External
   * @param endpoint
   * @param params
   * @returns {*}
   */
  static getExternal(endpoint, params) {

    return axios({
      method: 'GET',
      url: Constants.APP_URL + '/api/v1/' + endpoint,
      params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    });
  }
}

<template>
    <div class="text-right" v-if="$route.params.selectedTab !== undefined">
        <v-btn
            v-if="$route.params.selectedTab.indexOf('feed-') > -1 || $route.params.selectedTab.indexOf('preview') > -1"
            class="ma-2"
            outlined
            :to="'/socialmosaic/' + $route.params.id + '/edit'"
        >Edit</v-btn>
        <v-btn
            v-if="$route.params.selectedTab.indexOf('feed-') <= -1"
            class="ma-2"
            outlined
            :to="'/socialmosaic/' + $route.params.id + '/feed-pending'"
        >Feed</v-btn>
        <v-btn
            v-if="$route.params.selectedTab.indexOf('preview') <= -1"
            class="ma-2"
            outlined
            :to="'/socialmosaic/' + $route.params.id + '/preview'"
        >Preview</v-btn>
    </div>
</template>

<script>
    export default {

        name: 'SocialMosaicMenu'

    };
</script>

<template>
    <v-dialog
        v-model="showProfile"
        width="500"
        class="user-profile__dialog"
        v-if="user.email"
    >
        <template #activator="{ on: openModal }">
            <v-tooltip left>
                <template #activator="{ on: tooltip }">
                    <v-btn
                        icon
                        v-on="{ ...tooltip, ...openModal }"
                        @click="showProfile = !showProfile"
                    >
                        <v-avatar size="30">
                            <v-img v-if="userPhoto !== null" :src="userPhoto">
                            </v-img>
                            <v-icon v-else size="30">fas fa-user-circle</v-icon>
                        </v-avatar>
                    </v-btn>
                </template>
                <span>View Profile</span>
            </v-tooltip>
        </template>

        <v-card
            class="max-auto user-profile__dialog__content"
            max-width="500"
            tile
        >
            <v-card-title class="headline indigo lighten-1" primary-title
                >User Profile</v-card-title
            >

            <v-card-text class="mt-16">
                <v-container>
                    <v-progress-linear
                        :active="isRequesting"
                        indeterminate
                        bottom
                        color="warning"
                    ></v-progress-linear>

                    <v-row>
                        <v-col cols="12" class="pa-0">
                            <v-avatar class="ml-8" color="grey" size="120">
                                <v-img
                                    v-if="userPhoto !== null"
                                    :src="userPhoto"
                                ></v-img>
                                <v-icon v-else size="160"
                                    >fas fa-user-circle</v-icon
                                >
                            </v-avatar>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col class="py-0" cols="12" align-self="start">
                            <v-list-item dark>
                                <v-list-item-content class="pb-12">
                                    <v-list-item-title class="title">{{
                                        user.username
                                    }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ user.email }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>

                    <v-row justify="flex-end">
                        <v-col cols="6">
                            <v-btn
                                @click="showProfile = false"
                                color="error"
                                dark
                                >Close</v-btn
                            >
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Constants from '@/constants';

export default {
    name: 'UserProfile',

    computed: {
        user() {
            return this.$store.getters['auth/user'];
        }
    },

    watch: {
        showProfile() {
            this.formInputs.currentPassword = '';
            this.formInputs.newPassword = '';
            this.formInputs.passwordConfirm = '';
        }
    },

    data: () => ({
        showProfile: false,
        isRequesting: false,
        passwordsMatch: false,
        baseURL: Constants.APP_URL,
        userPhoto: null,
        formInputs: {
            currentPassword: '',
            newPassword: '',
            passwordConfirm: ''
        },
        rules: {
            required: value => !!value || 'Field Required.'
        }
    }),

    mounted() {
        this.userPhoto = this.user.photo;
    },

    methods: {
        pictureChanged(evt) {
            if (evt.target.files.length > 0) {
                this.isRequesting = true;
                const file = evt.target.files[0];
                const formData = new FormData();
                formData.append('photo', file);
                const requestData = {
                    id: this.user.id,
                    data: formData
                };

                this.$store
                    .dispatch('auth/updateProfilePicture', requestData)
                    .then(() => {
                        this.$store.dispatch('auth/getUserData').then(data => {
                            this.userPhoto = data.photo;
                            this.isRequesting = false;
                        });
                    })
                    .catch(() => {});
            }
        },

        updatePassword() {
            const isValid = this.$refs.passwordForm.validate();

            if (isValid && this.passwordsMatch) {
                this.isRequesting = true;
                const requestData = {
                    id: this.user.id,
                    data: {
                        current_password: this.formInputs.currentPassword,
                        password: this.formInputs.newPassword,
                        password_confirmation: this.formInputs.passwordConfirm
                    }
                };

                this.$store
                    .dispatch('users/updatePassword', requestData)
                    .then(() => {
                        this.showProfile = false;
                        this.isRequesting = false;
                    })
                    .catch(() => {});
            }
        },

        onPswChange() {
            if (
                this.formInputs.newPassword === this.formInputs.passwordConfirm
            ) {
                this.passwordsMatch = true;
                return;
            }
            this.passwordsMatch = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.user-profile__dialog__content {
    overflow: hidden;
    height: 425px;
    background-size: 20px 20px;

    .request-progress {
        background-color: rgba(44, 124, 148, 0.6);

        .v-progress-circular {
            position: relative;
            top: 50%;
            left: 50%;
        }
    }
}
</style>

import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';
import _ from "lodash";

// initial state
const state = {
  status: '',
  placementStatus: '',
  sharingStatus: '',
  postsStatus: '',
  data: {},
  filters: {
    hashtags: [],
    hashtagsStatus: '',
    accounts: [],
    accountsStatus: '',
    individualPosts: [],
    individualPostsStatus: '',
    blockedWords: [],
    blockedWordsStatus: '',
    languages: [],
    languagesStatus: '',
    blockedUsers: [],
    blockedUsersStatus: ''
  },
  langs: [],
  feedTypes: [],
  sharings: [],
  placements: [],
  sharingProviderTypes: [],
  posts: [],
  paginator: {
    current_page: 0,
    total_page: 0
  }
};

// Getters
const getters = {

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns current placement request status
   * @param state
   * @returns {*}
   */
  placementStatus: state => state.placementStatus,

  /**
   * Returns current sharing request status
   * @param state
   * @returns {*}
   */
  sharingStatus: state => state.sharingStatus,

  /**
   * Returns current posts request status
   * @param state
   * @returns {any}
   */
  postsStatus: state => state.postsStatus,

  /**
   * Returns current Social Mosaic data
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns available languages
   * @param state
   * @returns {any}
   */
  langs: state => state.langs,

  /**
   * Returns available Feed Types
   * @param state
   * @returns {any}
   */
  feedTypes: state => state.feedTypes,

  /**
   * Returns Filters: Hashtags
   * @param state
   * @returns {[]|Function}
   */
  hashtags: state => state.filters.hashtags,

  /**
   * Returns Filter Status: Hashtags
   * @param state
   * @returns {[]|Function}
   */
  hashtagsStatus: state => state.filters.hashtagsStatus,

  /**
   * Returns Filters: Accounts
   * @param state
   * @returns {[]|Function}
   */
  accounts: state => state.filters.accounts,

  /**
   * Returns Filter Status: Accounts
   * @param state
   * @returns {[]|Function}
   */
  accountsStatus: state => state.filters.accountsStatus,

  /**
   * Returns Filters: Individual Posts
   * @param state
   * @returns {[]|Function}
   */
  individualPosts: state => state.filters.individualPosts,

  /**
   * Returns Filter Status: Individual Posts
   * @param state
   * @returns {[]|Function}
   */
  individualPostsStatus: state => state.filters.individualPostsStatus,

  /**
   * Returns Filters: Blocked Words
   * @param state
   * @returns {[]|Function}
   */
  blockedWords: state => state.filters.blockedWords,

  /**
   * Returns Filter Status: Blocked Words
   * @param state
   * @returns {[]|Function}
   */
  blockedWordsStatus: state => state.filters.blockedWordsStatus,

  /**
   * Returns Filters: Languages
   * @param state
   * @returns {[]|Function}
   */
  languages: state => state.filters.languages,

  /**
   * Returns Filter Status: Languages
   * @param state
   * @returns {[]|Function}
   */
  languagesStatus: state => state.filters.languagesStatus,

  /**
   * Returns Filters: Blocked Users
   * @param state
   * @returns {[]|Function}
   */
  blockedUsers: state => state.filters.blockedUsers,

  /**
   * Returns Filter Status: Blocked Users
   * @param state
   * @returns {[]|Function}
   */
  blockedUsersStatus: state => state.filters.blockedUsersStatus,

  /**
   * Returns Sharing Links
   * @param state
   * @returns {any}
   */
  sharings: state => state.sharings,

  /**
   * Returns Placements
   * @param state
   * @returns {any}
   */
  placements: state => state.placements,

  /**
   * Returns the available sharing provider types
   * @param state
   * @returns {any}
   */
  sharingProviderTypes: state => state.sharingProviderTypes,

  /**
   * Returns social mosaic posts
   * @param state
   * @returns {any}
   */
  posts: state => state.posts,

  /**
   * Returns social mosaic posts paginator
   * @param state
   * @returns {*}
   */
  paginator: state => state.paginator
};

/**
 * Returns boolean to know if we can get
 * more pages
 * @param paginator
 * @returns {boolean}
 * @private
 */
function _canGetMore(paginator) {

  return (paginator.current_page < paginator.total_pages);
}

// Actions
const actions = {

  /**
   * Returns requested Social Mosaic
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  get({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.get('socialmosaics/' + id)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          commit('SET_SOCIALMOSAIC', response.data);
          Logger('success', 'Social Mosaic').log('Social Mosaic Info retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Creates New Social Mosaic
   * @param commit
   * @param socialmosaic
   * @returns {Promise<unknown>}
   */
  new({commit}, socialmosaic) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.post('socialmosaics', socialmosaic)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates Social Mosaic
   * @param commit
   * @param socialmosaic
   * @returns {Promise<unknown>}
   */
  update({commit}, socialmosaic) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.put('socialmosaics/' + socialmosaic.id, socialmosaic.data)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve({
            message: response.message,
            id: socialmosaic.id
          });
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Available Languages
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getLangs({commit}) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.get('socialmosaics/c/langs')
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          commit('SET_LANGS', response.data);
          Logger('success', 'Social Mosaic').log('Available Languages retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Available Feed Types
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getFeedTypes({commit}) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.get('socialmosaics/c/feed-types')
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          commit('SET_FEED_TYPES', response.data);
          Logger('success', 'Social Mosaic').log('Available Feed Types retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Hashtags
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getHashtags({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_HASHTAGS', 'loading');

      Rest.get('socialmosaics/' + id + '/hashtags')
        .then(response => {
          commit('SET_FILTER_HASHTAGS', response.data);
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('success', 'Social Mosaic').log('Filters: Hashtags retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Hashtag
   * @param commit
   * @param hashtag
   * @returns {Promise<unknown>}
   */
  newHashtag({commit}, hashtag) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_HASHTAGS', 'loading');

      Rest.post('socialmosaics/' + hashtag.socialMosaicId + '/hashtags', hashtag.hashtag)
        .then(response => {
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Hashtag
   * @param commit
   * @param hashtag
   * @returns {Promise<unknown>}
   */
  deleteHashtag({commit}, hashtag) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_HASHTAGS', 'loading');

      Rest.delete('socialmosaics/' + hashtag.socialMosaicId + '/hashtags/' + hashtag.hashtagId)
        .then(response => {
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_HASHTAGS', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Accounts
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getAccounts({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_ACCOUNTS', 'loading');

      Rest.get('socialmosaics/' + id + '/accounts')
        .then(response => {
          commit('SET_FILTER_ACCOUNTS', response.data);
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('success', 'Social Mosaic').log('Filters: Accounts retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Account
   * @param commit
   * @param account
   * @returns {Promise<unknown>}
   */
  newAccount({commit}, account) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_ACCOUNTS', 'loading');

      Rest.post('socialmosaics/' + account.socialMosaicId + '/accounts', account.account)
        .then(response => {
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Account
   * @param commit
   * @param account
   * @returns {Promise<unknown>}
   */
  deleteAccount({commit}, account) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_ACCOUNTS', 'loading');

      Rest.delete('socialmosaics/' + account.socialMosaicId + '/accounts/' + account.accountId)
        .then(response => {
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_ACCOUNTS', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Individual Posts
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getIndividualPosts({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_INDIVIDUAL_POSTS', 'loading');

      Rest.get('socialmosaics/' + id + '/individualposts')
        .then(response => {
          commit('SET_FILTER_INDIVIDUAL_POSTS', response.data);
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('success', 'Social Mosaic').log('Filters: Individual Posts retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Individual Post
   * @param commit
   * @param individualPost
   * @returns {Promise<unknown>}
   */
  newIndividualPost({commit}, individualPost) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_INDIVIDUAL_POSTS', 'loading');

      Rest.post('socialmosaics/' + individualPost.socialMosaicId + '/individualposts', individualPost.individualPost)
        .then(response => {
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Individual Post
   * @param commit
   * @param individualPost
   * @returns {Promise<unknown>}
   */
  deleteIndividualPost({commit}, individualPost) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_INDIVIDUAL_POSTS', 'loading');

      Rest.delete('socialmosaics/' + individualPost.socialMosaicId + '/individualposts/' + individualPost.individualPostId)
        .then(response => {
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_INDIVIDUAL_POSTS', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Blocked Words
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getBlockedWords({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_WORDS', 'loading');

      Rest.get('socialmosaics/' + id + '/blockedwords')
        .then(response => {
          commit('SET_FILTER_BLOCKED_WORDS', response.data);
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('success', 'Social Mosaic').log('Filters: Blocked Words retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Blocked Word
   * @param commit
   * @param blockedWord
   * @returns {Promise<unknown>}
   */
  newBlockedWord({commit}, blockedWord) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_WORDS', 'loading');

      Rest.post('socialmosaics/' + blockedWord.socialMosaicId + '/blockedwords', blockedWord.blockedWord)
        .then(response => {
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Blocked Word
   * @param commit
   * @param blockedWord
   * @returns {Promise<unknown>}
   */
  deleteBlockedWord({commit}, blockedWord) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_WORDS', 'loading');

      Rest.delete('socialmosaics/' + blockedWord.socialMosaicId + '/blockedwords/' + blockedWord.blockedWordId)
        .then(response => {
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_WORDS', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Languages
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getLanguages({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_LANGUAGES', 'loading');

      Rest.get('socialmosaics/' + id + '/languages')
        .then(response => {
          commit('SET_FILTER_LANGUAGES', response.data);
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('success', 'Social Mosaic').log('Filters: Languages retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Language
   * @param commit
   * @param language
   * @returns {Promise<unknown>}
   */
  newLanguage({commit}, language) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_LANGUAGES', 'loading');

      Rest.post('socialmosaics/' + language.socialMosaicId + '/languages', language.language)
        .then(response => {
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Language
   * @param commit
   * @param language
   * @returns {Promise<unknown>}
   */
  deleteLanguage({commit}, language) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_LANGUAGES', 'loading');

      Rest.delete('socialmosaics/' + language.socialMosaicId + '/languages/' + language.languageId)
        .then(response => {
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_LANGUAGES', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Filters: Blocked Users
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getBlockedUsers({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_USERS', 'loading');

      Rest.get('socialmosaics/' + id + '/blockedusers')
        .then(response => {
          commit('SET_FILTER_BLOCKED_USERS', response.data);
          commit('SET_STATUS_BLOCKED_USERS', 'loading');
          Logger('success', 'Social Mosaic').log('Filters: Blocked Users retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_USERS', 'loading');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Filter: Blocked User
   * @param commit
   * @param blockedUser
   * @returns {Promise<unknown>}
   */
  newBlockedUser({commit}, blockedUser) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_USERS', 'loading');

      Rest.post('socialmosaics/' + blockedUser.socialMosaicId + '/blockedusers', blockedUser.blockedUser)
        .then(response => {
          commit('SET_BLOCKED_USER', blockedUser.blockedUser);
          commit('SET_STATUS_BLOCKED_USERS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_USERS', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Filter: Blocked User
   * @param commit
   * @param blockedUser
   * @returns {Promise<unknown>}
   */
  deleteBlockedUser({commit}, blockedUser) {

    return new Promise((resolve, reject) => {
      commit('SET_STATUS_BLOCKED_USERS', 'loading');

      Rest.delete('socialmosaics/' + blockedUser.socialMosaicId + '/blockedusers/' + blockedUser.blockedUserId)
        .then(response => {
          commit('SET_STATUS_BLOCKED_USERS', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SET_STATUS_BLOCKED_USERS', 'loaded');
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Saves Custom Posts from file
   * @param commit
   * @param customPosts
   * @returns {Promise<unknown>}
   */
  saveCustomPosts({commit}, customPosts) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.postMultipart('socialmosaics/' + customPosts.socialMosaicId + '/posts/custom', customPosts.customPosts)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Customizes Social Mosaic
   * @param commit
   * @param data
   * @returns {Promise<unknown>}
   */
  customize({commit}, data) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.postMultipart('socialmosaics/' + data.socialMosaicId + '/customize', data.data)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Sharings
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getSharings({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SHARINGS_REQUEST', 'loading');

      Rest.get('socialmosaics/' + id + '/sharing')
        .then(response => {
          commit('SHARINGS_REQUEST', 'loaded');
          commit('SET_SHARINGS', response.data);
          Logger('success', 'Social Mosaic').log('Sharings retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SHARINGS_REQUEST', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Sharing Link
   * @param commit
   * @param sharing
   * @returns {Promise<unknown>}
   */
  newSharing({commit}, sharing) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.post('socialmosaics/' + sharing.socialMosaicId + '/sharing', sharing.sharing)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Sharing Link
   * @param commit
   * @param sharing
   * @returns {Promise<unknown>}
   */
  deleteSharing({commit}, sharing) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.delete('socialmosaics/' + sharing.socialMosaicId + '/sharing/' + sharing.sharingId)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Placements
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getPlacements({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('PLACEMENTS_REQUEST', 'loading');

      Rest.get('socialmosaics/' + id + '/placements')
        .then(response => {
          commit('PLACEMENTS_REQUEST', 'loaded');
          commit('SET_PLACEMENTS', response.data);
          Logger('success', 'Social Mosaic').log('Placements retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('PLACEMENTS_REQUEST', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Adds Placement
   * @param commit
   * @param placement
   * @returns {Promise<unknown>}
   */
  newPlacement({commit}, placement) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.post('socialmosaics/' + placement.socialMosaicId + '/placements', placement.placement)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Placement
   * @param commit
   * @param placement
   * @returns {Promise<unknown>}
   */
  deletePlacement({commit}, placement) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.delete('socialmosaics/' + placement.socialMosaicId + '/placements/' + placement.placementId)
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Sharing Provider Types
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getSharingProviderTypes({commit}) {

    return new Promise((resolve, reject) => {
      commit('SOCIALMOSAIC_REQUEST', true);

      Rest.get('socialmosaics/c/sharing-providers')
        .then(response => {
          commit('SOCIALMOSAIC_REQUEST', false);
          commit('SET_SHARING_PROVIDER_TYPES', response.data);
          Logger('success', 'Social Mosaic').log('Available Sharing Provider Types retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SOCIALMOSAIC_REQUEST', false);
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Returns Social Mosaic Posts
   * @param commit
   * @param state
   * @param data
   * @returns {Promise<unknown>}
   */
  getPosts({commit, state}, data = {
    socialMosaicId: 0,
    action: 'pending',
    keywords: ''
  }) {

    return new Promise((resolve, reject) => {
      let url = 'socialmosaics/' + data.socialMosaicId + '/posts/' + data.action
      let nextPage = null;
      const keywords = (!_.isUndefined(data.keywords) && data.keywords !== '') ? data.keywords : null;
      commit('POSTS_REQUEST', 'loading');

      if (_.isUndefined(state.paginator.next_page_url)) {
        nextPage = 1;
      } else if (!_.isNull(state.paginator.next_page_url)) {
        nextPage = state.paginator.current_page + 1;
      }

      if (nextPage === null) {
        commit('POSTS_REQUEST', '');
        resolve(_canGetMore(state.paginator));
        return;
      }

      if (keywords !== null) {
        url += '/search?keyword=' + keywords + '&page=' + nextPage;
      } else {
        url += '?page=' + nextPage;
      }

      Rest.get(url)
        .then(response => {
          commit('POSTS_REQUEST', 'loaded');
          commit('SET_PAGINATOR', response.paginator);
          commit('SET_POSTS', response.data);
          Logger('success', 'Social Mosaic').log('Posts retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('POSTS_REQUEST', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Rejects Post
   * @param commit
   * @param post
   * @returns {Promise<unknown>}
   */
  executeActionOnPost({commit}, post) {

    return new Promise((resolve, reject) => {
      commit('POSTS_REQUEST', 'loading');

      Rest.put('socialmosaics/' + post.socialMosaicId + '/posts/' + post.postId + '/' + post.action)
        .then(response => {
          commit('REMOVE_POST', post.postId);
          commit('POSTS_REQUEST', 'loaded');
          Logger('success', 'Social Mosaic').log(response.message)();
          resolve(response);
        }).catch(error => {
          commit('POSTS_REQUEST', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Refresh Social Mosaic Posts
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  refreshPosts({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('POSTS_REQUEST', 'loading');

      Rest.post('socialmosaics/' + id + '/posts')
        .then(response => {
          Logger('success', 'Social Mosaic').log('Posts refreshed!')();
          resolve(response.message);
        }).catch(error => {
          commit('POSTS_REQUEST', 'loaded');
          Logger('error', 'Social Mosaic').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Resets Posts
   * @param commit
   */
  resetPosts({commit}) {
    commit('RESET_POSTS', false);
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

// mutations
const mutations = {

  SOCIALMOSAIC_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  PLACEMENTS_REQUEST(state, request) {
    state.placementStatus = request;
  },

  SHARINGS_REQUEST(state, request) {
    state.sharingStatus = request;
  },

  POSTS_REQUEST(state, request) {
    state.postsStatus = request;
  },

  SET_SOCIALMOSAIC(state, socialmosaic) {
    state.data = socialmosaic;
  },

  SET_FILTER_HASHTAGS(state, hashtags) {
    state.filters.hashtags = hashtags;
  },

  SET_STATUS_HASHTAGS(state, status) {
    state.filters.hashtagsStatus = status;
  },

  SET_FILTER_ACCOUNTS(state, accounts) {
    state.filters.accounts = accounts;
  },

  SET_STATUS_ACCOUNTS(state, status) {
    state.filters.accountsStatus = status;
  },

  SET_FILTER_INDIVIDUAL_POSTS(state, individualPosts) {
    state.filters.individualPosts = individualPosts;
  },

  SET_STATUS_INDIVIDUAL_POSTS(state, status) {
    state.filters.individualPostsStatus = status;
  },

  SET_FILTER_BLOCKED_WORDS(state, blockedWords) {
    state.filters.blockedWords = blockedWords;
  },

  SET_STATUS_BLOCKED_WORDS(state, status) {
    state.filters.blockedWordsStatus = status;
  },

  SET_FILTER_LANGUAGES(state, languages) {
    state.filters.languages = languages;
  },

  SET_STATUS_LANGUAGES(state, languages) {
    state.filters.languagesStatus = languages;
  },

  SET_FILTER_BLOCKED_USERS(state, blockedUsers) {
    state.filters.blockedUsers = blockedUsers;
  },

  SET_STATUS_BLOCKED_USERS(state, blockedUsers) {
    state.filters.blockedUsersStatus = blockedUsers;
  },

  SET_LANGS(state, langs) {
    state.langs = langs;
  },

  SET_FEED_TYPES(state, feedTypes) {
    state.feedTypes = feedTypes;
  },

  SET_SHARINGS(state, sharings) {
    state.sharings = sharings;
  },

  SET_PLACEMENTS(state, placements) {
    state.placements = placements;
  },

  SET_SHARING_PROVIDER_TYPES(state, sharingProviderTypes) {
    state.sharingProviderTypes = sharingProviderTypes;
  },

  SET_POSTS(state, posts) {
    _.each(posts, post => {
      post.selected = false;
      state.posts.push(post);
    });
  },

  REMOVE_POST(state, id) {
    const postIndex = _.findIndex(state.posts, {id});
    state.posts.splice(postIndex, 1);
  },

  SET_PAGINATOR(state, paginator) {
    state.paginator = paginator;
  },

  SET_BLOCKED_USER(state, blockedUser) {
     _.forEach(state.posts, (post, postIndex) => {
      if (
        post.user === blockedUser.name
        && post.feeds_type_id === blockedUser.feeds_type_id
      ) {
        state.posts.splice(postIndex, 1);
      }
    });
  },

  RESET_POSTS(state) {
    state.posts = [];
    state.paginator = {
      current_page: 0,
      total_page: 0
    };
  },

  RESET(state) {
    state.status = '';
    state.placementStatus = '';
    state.sharingStatus = '';
    state.postsStatus = '';
    state.data = {};
    state.filters = {
      hashtags: [],
      hashtagsStatus: '',
      accounts: [],
      accountsStatus: '',
      individualPosts: [],
      individualPostsStatus: '',
      blockedWords: [],
      blockedWordsStatus: '',
      languages: [],
      languagesStatus: '',
      blockedUsers: [],
      blockedUsersStatus: ''
    };
    state.langs = [];
    state.feedTypes = [];
    state.sharings = [];
    state.placements = [];
    state.sharingProviderTypes = [];
    state.posts = [];
    state.paginator = {
      current_page: 0,
      total_page: 0
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

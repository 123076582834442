import { LoggerFactory } from 'lines-logger';

/**
 * Logger Service
 * @param type
 * @param tag
 * @returns factory
 */
export const Logger = (type = 'log', tag = '') => {
  const _isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  const _isFirefox = /firefox/i.test(navigator.userAgent);
  const factory = new LoggerFactory();
  factory.setLogWarnings(3);
  let color = 'blue';

  if (!_isChrome && !_isFirefox) {
    factory.setLogWarnings(8);
  }

  switch (type) {
    case 'main':
      color =  'purple';
      break;
    case 'emit':
    case 'success':
      color = 'green';
      break;
    case 'on':
      color = '#f4d442';
      break;
    case 'warn':
      color = 'orange';
      break;
    case 'error':
      color = 'red';
      break;
    case 'info':
    case 'debug':
      color = 'gray';
      break;
    case 'log':
    default:
      color = 'blue';
      break;
  }

  return factory.getLoggerColor((' [FLOW] ' + tag), color);
};

/**
 * [HOW TO USE IT]
 *
 * logger({COLORTYPE}, {TAG}).{LOGTYPE}({DATA})();
 *
 * {COLORTYPE}: options can be: 'debug', 'warn', 'error', 'info', 'log'
 * see colors above
 *
 * {TAG}: right now the first thing that will be logged with background color
 * is the word [ORIGIN], but you can add something else to be added next to this
 *
 * {LOGTYPE}: there are multiple ways to log: using 'log', 'error', 'debug', 'trace', 'warn'
 *
 * {DATA}: is whatever you need in there: a string, an array, an object, a string + array, etc
 * it will be like doing something like this:
 * console.log('logging');
 * let arr = [1,2,3];
 * console.log(arr);
 * console.log('logging', arr);
 *
 * Reference: https://github.com/akoidan/lines-logger
 *
 * */

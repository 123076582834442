import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  data: {},
  recipients: [],
  paginator: {}
};

// Getters
const getters = {
  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  states: state => state.status,

  /**
   * Returns current tracker data.
   * @param state
   * @returns {*}
   */
  data: state => state.data
};

// Actions
const actions = {
  /**
   * Creates new Tracker creative
   * @param commit
   * @param requestData
   */
  new({commit}, requestData) {
    return new Promise((resolve, reject) => {
      commit('TRACKER_REQUEST', true);

      Rest.postMultipart('trackers', requestData)
        .then(response => {
          commit('TRACKER_REQUEST', false);
          Logger('success', 'Tracker').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('TRACKER_REQUEST', false);
          Logger('error', 'Tracker').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates desired tracker creative
   * @param commit
   * @param creative
   * @returns {Promise<unknown>}
   */
  update({commit}, creative) {
    commit('TRACKER_REQUEST', true);

    return new Promise((resolve, reject) => {
      Rest.postMultipart('trackers/' + creative.id, creative.data).then(response => {
        commit('TRACKER_REQUEST', false);
        Logger('success', 'Tracker').log(response.message)();
        resolve({
          message: response.message,
          id: creative.id
        });
      }).catch(error => {
        commit('TRACKER_REQUEST', false);
          Logger('error', 'Tracker').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Get tracker creative
   * @param commit
   * @param id
   */
  get({commit}, id) {
    return new Promise((resolve, reject) => {
      commit('TRACKER_REQUEST', true);

      Rest.get('trackers/' + id)
        .then(response => {
          commit('TRACKER_REQUEST', false);
          commit('SET_TRACKER', response.data);
          Logger('success', 'Tracker').log('Tracker info retrieved!')();
          resolve(response.data);
        }).catch(error => {
          commit('TRACKER_REQUEST', false);
          Logger('error', 'Tracker').log('Error: ', error)();
          reject(error);
        });

    });
  },

  /**
   * Toggles delivered state of creative
   * @param commit
   * @param requestData
   * @returns {Promise<unknown>}
   */
  toggleStatus({commit}, requestData) {
    return new Promise((resolve, reject) => {
      commit('TRACKER_REQUEST', true);

      Rest.patch('trackers/' + requestData.creativeID + '/toggle').then(response => {
        commit('TOGGLE', requestData.toggle);
        resolve();
        Logger('success', 'Tracker').log(response.message)();
      }).catch(error => {
        Logger('error', 'Tracker').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Requests clear all answers
   * @param commit
   * @param requestData
   * @returns {Promise<unknown>}
   */
   clear({commit}, requestData) {

    return new Promise((resolve, reject) => {
      commit('TRACKER_REQUEST', true);

      Rest.delete('trackers/' + requestData.id + '/clear/' + requestData.type)
        .then(response => {
          commit('TRACKER_REQUEST', false);
          Logger('success', 'Tracker').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('TRACKER_REQUEST', false);
          Logger('error', 'Tracker').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

const mutations = {

  TRACKER_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_TRACKER(state, creative) {
    state.data = creative;
  },

  TOGGLE(state, enabled) {
    state.data.enabled = enabled;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
    <v-dialog
        v-model="showPurge"
        width="500"
    >
        <template #activator="{ on: openModal }">
            <v-list-item
                v-on="{...openModal}"
                @click="showPurge = !showPurge"
            >
                <v-list-item-action>
                    <v-icon>fas fa-broom</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Purge
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>

        <v-card class="max-auto" max-width="500" tile>
            <v-card-title
                class="headline indigo lighten-1"
                primary-title
            >Purge</v-card-title>

            <v-progress-linear
                :active="isRequesting"
                indeterminate
                bottom
                color="warning"
            ></v-progress-linear>

            <v-form
                @submit.prevent="purge"
                ref="form"
                v-model="formValid"
            >
                <v-card-text >
                    <v-radio-group
                        v-model="type"
                        row
                    >
                        <v-radio
                            label="Url"
                            value="url"
                        ></v-radio>
                        <v-radio
                            label="Hostname"
                            value="hostname"
                        ></v-radio>
                    </v-radio-group>

                    <v-text-field
                        v-if="type === 'url'"
                        label="Url"
                        type="url"
                        v-model="url"
                        :rules="[rules.required, rules.url, rules.evolvemedia]"
                        outlined
                        placeholder="http://ac-xxxxx-iframe.evolvemediallc.com/something.ext"
                    ></v-text-field>

                    <v-text-field
                        v-else
                        label="Hostname"
                        type="text"
                        v-model="hostname"
                        :rules="[rules.required, rules.evolvemedia]"
                        outlined
                        placeholder="ac-xxxxx-iframe.evolvemediallc.com"
                    ></v-text-field>
                </v-card-text>

                 <v-divider></v-divider>

                <v-card-actions>
                     <v-btn
                        @click="showPurge = false"
                        color="error"
                        dark
                    >Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="isRequesting"
                        type="submit"
                        color="indigo"
                    >Purge</v-btn>
                </v-card-actions>
             </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
    import Rest from '@/services/rest.service';
    import { Logger } from '@/services/logger.service';

    export default {

        name: 'Purge',

        data: () => ({
            type: 'url',
            showPurge: false,
            isRequesting: false,
            url: '',
            hostname: '',
            rules: {
                required: value => !!value || 'Field Required.',
                url: value => {
                    const pattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
                    return pattern.test(value) || 'Invalid url';
                },
                evolvemedia: value => value.indexOf('evolvemediallc.com') > -1 || 'Not an Evolve Media Url'
            },
            formValid: true
        }),

        methods: {

            purge() {
                this.$refs.form.validate();

                if (this.formValid) {
                    this.isRequesting = true;
                    const call = this.type === 'url' ? Rest.post('purge/url', {
                        url: this.url
                    }) : Rest.post('purge/hostname', {
                        hostname: this.hostname
                    });

                    call.then(response => {
                        Logger('success', 'Purge').log(response.message)();
                        this.isRequesting = false;

                        setTimeout(() => {
                            this.showPurge = false;
                        }, 300);
                    }).catch(error => {
                        Logger('error', 'Purge').log('Error:', error)();
                        this.isRequesting = false;
                    });
                }
            }
        }
    }
</script>

const contexts = new WeakMap();

export default new class LoadingService {

  /**
   * Loading Service Constructor
   */
  constructor() {
    contexts.set(this, {
      loading: {
        visible: false,
        type: 'circular',
        calls: []
      }
    });
  }

  /**
   * Returns laoding instance
   * @returns {*}
   */
  get loading() {
    return contexts.get(this).loading;
  }

  /**
   * Hides Loading
   */
  hide() {
    contexts.get(this).loading.calls.pop();

    if (contexts.get(this).loading.calls.length <= 0) {
      contexts.get(this).loading.visible = false;
    }
  }

  /**
   * Shows Loading
   * @param type
   */
  show(type = 'circular') {
    contexts.get(this).loading.type = type;
    contexts.get(this).loading.visible = true;
    contexts.get(this).loading.calls.push({});
  }
}();


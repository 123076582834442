import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';
import _ from 'lodash';

// initial state
const state = {
  status: '',
  data: {},
  entries: [],
  paginator: {
    current_page: 0,
    total_page: 0
  },
  fieldTypes: [],
  fields: []
};

// Getters
const getters = {

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Returns current sweepstake data
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns current sweepstake entries
   * @param state
   * @returns {*}
   */
  entries: state => state.entries,

  /**
   * Returns entries paginator
   * @param state
   * @returns {*}
   */
  paginator: state => state.paginator,

  /**
   * Returns field types
   * @param state
   * @returns {*}
   */
  fieldTypes: state => state.fieldTypes,

  /**
   * Returns sweepstake's fields
   * @param state
   * @returns {*}
   */
  fields: state => state.fields
};


/**
 * Returns boolean to know if we can get
 * more pages
 * @param paginator
 * @returns {boolean}
 * @private
 */
function _canGetMore(paginator) {

  return (paginator.current_page < paginator.total_pages);
}

// Actions
const actions = {

  /**
   * Returns requested sweepstake
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  get({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.get('sweepstakes/' + id)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          commit('SET_SWEEPSTAKE', response.data);
          Logger('success', 'Sweepstakes').log('Sweepstake Info retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Returns requested sweepstake's fields
   * @param commit
   * @param id
   * @returns {Promise<unknown>}
   */
  getFields({commit}, id) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.get('sweepstakes/' + id + '/fields')
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          commit('SET_FIELDS', response.data);
          Logger('success', 'Sweepstakes').log('Sweepstake Fields retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Request entries
   * @param commit
   * @param state
   * @param id
   * @returns {Promise<unknown>}
   */
  getEntries({commit, state}, id) {

    return new Promise((resolve, reject) => {
      let nextPage = null;
      commit('SWEEPSTAKES_REQUEST', true);

      if (_.isUndefined(state.paginator.next_page_url)) {
        nextPage = 1;
      } else if (!_.isNull(state.paginator.next_page_url)) {
        nextPage = state.paginator.current_page + 1;
      }

      if (nextPage === null) {
        commit('SWEEPSTAKES_REQUEST', false);
        resolve(_canGetMore(state.paginator));
        return;
      }

      Rest.get('sweepstakes/' + id + '/entries?page=' + nextPage)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          commit('SET_PAGINATOR', response.paginator);
          commit('SET_ENTRIES', response.data);
          Logger('success', 'Sweepstakes').log('Entries retrieved')();
          resolve(response);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Deletes entry
   * @param commit
   * @param params
   * @returns {Promise<unknown>}
   */
  deleteEntry({commit}, params) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.delete('sweepstakes/' + params.id + '/entries/' + params.entryId)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          commit('DELETE_ENTRY', params.entryId);
          Logger('success', 'Sweepstakes').log('Entry deleted succesfully!')();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Returns Field Types
   * @param commit
   * @returns {Promise<unknown>}
   */
  getFieldTypes({commit}) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.get('sweepstakes/c/input-types')
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          commit('SET_FIELDTYPES', response.data);
          Logger('success', 'Sweepstakes').log('Input field types retrieved')();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log(error)();
          reject(error);
      });
    });
  },

  /**
   * Creates New Sweepstake
   * @param commit
   * @param sweepstake
   * @returns {Promise<unknown>}
   */
  new({commit}, sweepstake) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.post('sweepstakes', sweepstake)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('success', 'Sweepstakes').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Updates Sweepstake
   * @param commit
   * @param sweepstake
   * @returns {Promise<unknown>}
   */
  update({commit}, sweepstake) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.put('sweepstakes/' + sweepstake.id, sweepstake.data)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('success', 'Sweepstakes').log(response.message)();
          resolve({
            message: response.message,
            id: sweepstake.id
          });
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Creates New Sweepstake Field
   * @param commit
   * @param sweepstake
   * @returns {Promise<unknown>}
   */
  newField({commit}, sweepstake) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.post('sweepstakes/' + sweepstake.id + '/fields', sweepstake.field)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('success', 'Sweepstakes').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Deletes Sweepstake Field
   * @param commit
   * @param sweepstake
   * @returns {Promise<unknown>}
   */
  deleteField({commit}, sweepstake) {

    return new Promise((resolve, reject) => {
      commit('SWEEPSTAKES_REQUEST', true);

      Rest.delete('sweepstakes/' + sweepstake.id + '/fields/' + sweepstake.fieldId)
        .then(response => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('success', 'Sweepstakes').log(response.message)();
          resolve(response.data);
        }).catch(error => {
          commit('SWEEPSTAKES_REQUEST', false);
          Logger('error', 'Sweepstakes').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({commit}) {
    commit('RESET', false);
  }
};

// mutations
const mutations = {

  SWEEPSTAKES_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_SWEEPSTAKE(state, sweepstake) {
    state.data = sweepstake;
  },

  SET_FIELDS(state, fields) {
    state.fields = fields;
  },

  SET_ENTRIES(state, entries) {
    _.each(entries, entry => {
      entry.selected = false;
      state.entries.push(entry);
    });
  },

  DELETE_ENTRY(state, entryId) {
    const index = _.findIndex(state.entries, {id: entryId});

    if (index !== -1) {
      state.entries.splice(index, 1);
      state.paginator.total--;
    }
  },

  SET_PAGINATOR(state, paginator) {
    state.paginator = paginator;
  },

  SET_FIELDTYPES(state, types) {
    state.fieldTypes = types;
  },

  RESET(state) {
    state.status = '';
    state.data = {};
    state.entries = [];
    state.paginator = {
      current_page: 0,
      total_page: 0
    };
    state.fieldTypes = [];
    state.fields = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import axios from 'axios';
import decode from 'jwt-decode';
import Constants from '@/constants';
import mainStore from '@/store/index';
import { Logger } from '@/services/logger.service';
import Rest from '@/services/rest.service';

// initial state
const state = {
  status: '',
  token: localStorage.getItem('access_token') || '',
  // user: localStorage.getItem('u') ? decode(localStorage.getItem('u')).user : {},
  user: localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : {},
  // encodedUser: localStorage.getItem('u') || '',
  userStatus: ''
};

// Getters
const getters = {

  /**
   * Checks if user is logged in
   * @param state
   * @returns {boolean}
   */
  isLoggedIn: state => !!state.token,

  /**
   * Returns authentication status
   * @param state
   * @returns {*}
   */
  status: state => state.status,

  /**
   * Current token
   * @param state
   * @returns {any}
   */
  token: state => state.token,

  /**
   * Returns expiration date of current token
   * @param state
   * @returns {null|Date}
   */
  getTokenExpirationDate: state => {
    const token = decode(state.token);
    if (!token.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(token.exp);

    return date;
  },

  /**
   * Checks if token is expired
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isTokenExpired: (state, getters) => {

    return getters.getTokenExpirationDate < new Date();
  },

  /**
   * Returns current authenticated user
   * @param state
   * @returns {any}
   */
  user: state => state.user,

  /**
   * Returns current user request status
   * @param state
   * @returns {*}
   */
  userStatus: state => state.userStatus,
};


// Actions
const actions = {

  setToken({commit}, token) {
    commit('AUTH_REQUEST', true);

    localStorage.setItem('access_token', token);
    axios.defaults.headers.common['Authorization'] = token;

    commit('AUTH_SUCCESS', token);
    Logger('success', 'Login').log('Access token assigned')();
  },

  /**
   * Logs in
   * @param commit
   * @param user
   * @returns {Promise<unknown>}
   */
  login({commit}, user) {

    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST', true);

      axios({
        url: Constants.APP_URL + '/api/v1/i/auth/login',
        data: user,
        method: 'POST'
      }).then(response => {
        const token = response.data.access_token;
        localStorage.setItem('access_token', token);
        axios.defaults.headers.common['Authorization'] = token;
        commit('AUTH_SUCCESS', token);
        Logger('success', 'Login').log('Access token retrieved')();
        resolve(response.data);
      }).catch(error => {
        commit('AUTH_ERROR');
        commit('AUTH_REQUEST', false);
        Logger('error', 'Login').log(error)();
        localStorage.removeItem('access_token');
        localStorage.removeItem('u');
        reject(error);
      });
    });
  },

  /**
   * Requests user data
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   */
  getUserData({commit, state}) {

    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST', true);

      axios({
        url: Constants.APP_URL + '/api/v1/i/auth/userdata?token=' + state.token,
        method: 'GET'
      }).then(response => {
        // const encodedUser = response.data;
        // const user = decode(encodedUser);
        if (localStorage.getItem('u') !== null) {
          localStorage.removeItem('u');
        }
        // localStorage.setItem('u', encodedUser);
        localStorage.setItem('u', JSON.stringify(response.data));

        commit('AUTH_USER', {
          // encodedUser,
          // user: user.user
          user: response.data
        });
        Logger('success', 'Get User Data').log('User data retrieved')();
        resolve(response.data);
      }).catch(error => {
        commit('AUTH_ERROR');
        commit('AUTH_REQUEST', false);
        Logger('error', 'Get User Data').log(error)();
        reject(error);
      });
    });
  },

  /**
   * Executes log out
   * @param commit
   * @returns {Promise<unknown>}
   */
  logout({commit}) {

    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST', true);

      axios({
        url: Constants.APP_URL + '/api/v1/i/auth/logout',
        method: 'POST'
      }).then(() => {
        commit('LOGOUT');
        mainStore.dispatch('reset');
        Logger('success', 'Logout').log('Done')();
        resolve();
      }).catch(error => {
        commit('AUTH_REQUEST', false);
        Logger('error', 'Logout').log(error)();
        reject(error);
      });
    });
  },

  /**
   * Executes forgot password.
   * @param commit
   * @param email
   * @returns {Promise<unknown>}
   */
  forgotPassword({commit}, requestParams) {

    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST', true);
      Rest.post('auth/forgot-password', {
        email: requestParams.email
      }).then(response => {
        commit('AUTH_REQUEST', false);
        Logger('success', 'Forgot Password').log('Password Reset sent to email.')();
        resolve(response);
      }).catch(error => {
        commit('AUTH_REQUEST', false);
        Logger('error', 'Forgot Password').log('Error:', error)();
        reject(error);
      });
    });
  },

  /**
   * Executes a password reset request.
   * @param commit
   * @param requestParams
   * @returns {Promise<unknown>}
   */
  passwordReset({commit}, requestParams) {

    return new Promise((resolve, reject) => {
      commit('AUTH_REQUEST', true);

      Rest.post('auth/reset-password', {
       token: requestParams.token,
       email: requestParams.email,
       password: requestParams.password,
       password_confirmation: requestParams.passwordConfirm
      }).then(response => {
        commit('AUTH_REQUEST', false);
        Logger('success', 'Reset Password').log('Password Updated Correctly!.')();
        resolve(response);
      }).catch(error => {
        commit('AUTH_REQUEST', false);
        Logger('error', 'Password Reset').log('Error:', error)();
        reject(error);
      });
    });
  },

  /**
   * Updates the desired user profile picture.
   * @param commit
   * @param requestData
   */
  updateProfilePicture({commit}, requestData) {

    return new Promise((resolve, reject) => {
      commit('USER_REQUEST', true);

      Rest.postMultipart('users/' + requestData.id + '/changephoto', requestData.data)
        .then(response => {
          commit('USER_REQUEST', false);
          Logger('success', 'Users').log(response.message)();
          resolve(response);
        }).catch(error => {
          commit('USER_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error)
        });
    });
  },

  /**
   * Updates the password from profile window.
   * @param commit
   * @param requestData
   */
  updatePassword({commit}, requestData) {

    return new Promise((resolve, reject) => {
      commit('USER_REQUEST', true);

      Rest.patch('users/' + requestData.id + '/changepassword', requestData.data)
        .then(response => {
          commit('USER_REQUEST', false);
          Logger('success', 'Users').log(response.message)();
          resolve(response);
        }).catch(error => {
          commit('USER_REQUEST', false);
          Logger('error', 'Users').log('Error: ', error)();
          reject(error)
        });
    });
  }
};

// mutations
const mutations = {

  AUTH_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  AUTH_SUCCESS(state, token) {
    state.status = 'success';
    state.token = token;
  },

  AUTH_USER(state, user) {
    // state.encodedUser = user.encodedUser;
    state.user = user.user;
  },

  AUTH_ERROR(state) {
    state.status = 'error';
  },

  LOGOUT(state) {
    state.status = '';
    state.token = '';
    state.user = {};
    // state.encodedUser = '';
  },

  USER_REQUEST(state, request) {
    state.userStatus = request ? 'loading' : '';
  },

  RESET(state) {
    state.status = '';
    state.token = localStorage.getItem('access_token') || '';
    // state.user = localStorage.getItem('u') ? decode(localStorage.getItem('u')).user : {};
    state.user = localStorage.getItem('u') ? JSON.parse(localStorage.getItem('u')) : {};
    // state.encodedUser = localStorage.getItem('u') || '';
    state.userStatus = '';
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
    <div :class="{
        'loading': true,
        'loading--circular': type === 'circular',
        'loading--linear': type === 'linear'
    }">
        <v-overlay v-if="type === 'circular'">
            <v-progress-circular
                :size="50"
                color="blue-grey darken-1"
                indeterminate
            ></v-progress-circular>
        </v-overlay>
        <v-progress-linear
            v-else
            color="blue-grey darken-1"
            indeterminate
            rounded
            height="6"
        ></v-progress-linear>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: ['type']
    };
</script>

<style lang="scss" scoped>
    .loading {

        &--linear {
            left: 0px;
            position: absolute;
            top: 64px;
            width: 100%;
            z-index: 999;
        }
    }
</style>

import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';
import _ from 'lodash';

// initial state
const state = {
  status: '',
  data: {},
  analytics: []
};

// Getters
const getters = {
  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  states: state => state.status,

  /**
   * Returns current Standalone data.
   * @param state
   * @returns {*}
   */
  data: state => state.data,

  /**
   * Returns standalone analytics
   * @param {*} state
   * @returns
   */
  analytics: state => state.analytics
};

// Actions
const actions = {
  /**
   * Creates new Standalone creative
   * @param commit
   * @param requestData
   */
  new({ commit }, requestData) {
    return new Promise((resolve, reject) => {
      commit('STANDALONE_REQUEST', true);

      Rest.postMultipart('standalones', requestData)
        .then(response => {
          commit('STANDALONE_REQUEST', false);
          Logger('success', 'Standalone').log(response.message)();
          resolve(response.data);
        })
        .catch(error => {
          commit('STANDALONE_REQUEST', false);
          Logger('error', 'Standalone').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Updates desired tracker creative
   * @param commit
   * @param creative
   * @returns {Promise<unknown>}
   */
  update({ commit }, creative) {
    commit('STANDALONE_REQUEST', true);

    return new Promise((resolve, reject) => {
      Rest.postMultipart('standalones/' + creative.id, creative.data)
        .then(response => {
          commit('STANDALONE_REQUEST', false);
          Logger('success', 'Standalone').log(response.message)();
          resolve({
            message: response.message,
            id: creative.id
          });
        })
        .catch(error => {
          commit('STANDALONE_REQUEST', false);
          Logger('error', 'Standalone').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Get tracker creative
   * @param commit
   * @param id
   */
  get({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit('STANDALONE_REQUEST', true);

      Rest.get('standalones/' + id)
        .then(response => {
          commit('STANDALONE_REQUEST', false);
          commit('SET_STANDALONE', response.data);
          Logger('success', 'Standalone').log('Tracker info retrieved!')();
          resolve(response.data);
        })
        .catch(error => {
          commit('STANDALONE_REQUEST', false);
          Logger('error', 'Standalone').log('Error: ', error)();
          reject(error);
        });
    });
  },

  /**
   * Returns a video analytics
   * @param commit
   * @param data
   * @returns {Promise<unknown>}
   */
  getAnalytics({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit('STANDALONE_REQUEST', true);

      Rest.get(
        'standalones/' +
          data.id +
          '/analytics/' +
          data.startDate +
          '/' +
          data.endDate
      )
        .then(response => {
          commit('SET_ANALYTICS', response.data);
          Logger('success', 'Standalone').log(
            'Single retrieved',
            response.data
          )();
          resolve(response.data);
        })
        .catch(error => {
          commit('STANDALONE_REQUEST', false);
          Logger('error', 'Standalone').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Reset to initial state
   * @param commit
   */
  reset({ commit }) {
    commit('RESET', false);
  }
};

const mutations = {
  STANDALONE_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_STANDALONE(state, creative) {
    state.data = creative;
  },

  SET_ANALYTICS(state, analytics) {
    state.analytics = _.cloneDeep(analytics);
  },

  RESET(state) {
    state.status = '';
    state.data = {};
    state.analytics = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

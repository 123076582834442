<template>
    <v-app id="inspire">
        <f-navigation
            v-if="isLoggedIn && user !== null"
            @drawerToggled="drawer = $event"
            :drawer="drawer"
        ></f-navigation>
        <f-header
            v-if="isLoggedIn && user !== null"
            @toggleDrawer="drawer = !drawer"
        ></f-header>
        <f-notification
            :notify="notify.visible"
            :type="notify.type"
            :message="notify.message"
            @closeNotification="closeNotification"
        ></f-notification>
        <v-main>
            <f-toolbar
                v-if="isLoggedIn && user !== null"
                :currentRouteName="currentRouteName"
                @keywords="onKeywordsPressed"
                :loading="loading"
                :drawer="drawer"
            ></f-toolbar>

            <v-container
                :class="{
                    'fill-height': (currentRouteName === 'Login' || currentRouteName === 'Reset-password'),
                    'route-container': true
                }"
                fluid
            >
                <v-row
                    :align="(currentRouteName === 'Login' || currentRouteName === 'Reset-password') ? 'center' : 'start'"
                    justify="center"
                >
                    <v-col align="center">
                        <router-view :keywords="keywords" />
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <v-footer app>
            <span>Flow App &copy; {{ year }}</span>
        </v-footer>
    </v-app>
</template>

<script>
    import NotificationService from '@/services/notification.service';
    import LoadingService from '@/services/loading.service';
    import Header from '@/components/Header.vue';
    import Navigation from '@/components/Navigation.vue';
    import Notification from '@/components/Notification.vue';
    import Toolbar from '@/components/Toolbar.vue';
    import _ from 'lodash';

    export default {

        name: 'App',

        data: () => ({
            drawer: false,
            notify: NotificationService.notify,
            loading: LoadingService.loading,
            keywords: '',
            year: new Date().getFullYear()
        }),

        computed: {

            isLoggedIn() {

                return this.$store.getters['auth/isLoggedIn'];
            },

            user() {

                return _.isEmpty(this.$store.getters['auth/user']) ? null : this.$store.getters['auth/user'];
            },

            currentRouteName() {
                let name = '';

                if (
                    this.$route.name !== 'dashboard'
                    && this.$route.name !== 'users'
                ) {
                    name = (this.$route.params.id === 'new' ? 'New ' : 'Edit ') + this.$route.meta.label;
                } else {
                    name = _.capitalize(this.$route.name)
                }

                return name;
            }
        },

        watch: {

            isLoggedIn(val) {
                if (val) {
                    this.setSession();
                } else {
                    delete window['flowSession'];
                }
            }
        },

        methods: {

            setSession() {
                const currentDate = new Date();
                const expirationDate = new Date(this.$store.getters['auth/getTokenExpirationDate']);
                const ms = Math.abs(currentDate.getTime() - expirationDate.getTime());

                window['flowSession'] = setTimeout(() => {
                    this.$store.dispatch('reset');
                    this.$router.push('login');
                    delete window['flowSession'];
                }, ms);
            },

            closeNotification() {
                NotificationService.hide();
            },

            goTo(route) {
                this.$router.push(route);
            },

            onKeywordsPressed(kw) {
                this.keywords = kw;
            }
        },

        components: {
            FHeader: Header,
            FNavigation: Navigation,
            FNotification: Notification,
            FToolbar: Toolbar
        }
    };
</script>

<style lang="scss" scoped>
    .v-main {
        padding-top: 48px !important;
    }

    .route-container {
        padding-top: 70px;
    }
</style>

<template>
    <v-menu offset-y class="text-center" v-if="types.length > 0">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-on="{ ...tooltip, ...menu }">
                <v-icon>fa-plus-circle</v-icon>
            </v-btn>
          </template>
          <span>New Creative</span>
        </v-tooltip>
      </template>
      <v-list>
            <v-list-group value="true" :disabled="true" no-action>
                <template v-slot:activator>
                  <v-list-item-title>Creative To Create</v-list-item-title>
                </template>
                <v-list-item
                    v-for="(item, index) in types"
                    :key="index"
                    @click="goTo(item.type)"
                >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
          </v-list-group>
      </v-list>
    </v-menu>
</template>

<script>
export default {

    name: 'NewCreativeMenu',

    computed: {

        types() {
            return this.$store.getters['creatives/types'];
        }
    },

    methods: {

        /**
         * Redirects to creative's new form.
         * @param creativeType
         */
        goTo(creativeType) {
            this.$router.push({
                name: creativeType,
                params: { id: 'new' }
            });
        }
    }
}
</script>

<template>
    <div :class="{
        'text-center': true,
        'ma-2': type === 'snackbar'
    }">
        <v-snackbar
            v-model="notification"
            v-if="type === 'snackbar'"
            top
            :timeout="5000"
        >
            <div v-html="message"></div>

            <v-btn
                color="pink" text
                @click="closeNotification"
            >Close</v-btn>
        </v-snackbar>

        <v-dialog v-model="notification" v-if="type === 'dialog'">
            <v-card>
                <v-card-title
                    class="headline"
                    primary-title
                    v-html="message.title"
                ></v-card-title>

                <v-card-text v-html="message.text"></v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary" text
                        @click="closeNotification"
                    >Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {

        name: 'Notification',

        props: [
            'notify', 'message', 'type'
        ],

        data: () => ({
            notification: false
        }),

        watch: {

            notify: {
                immediate: true,
                handler (val) {
                    this.notification = val;
                }
            },
            notification: {
                immediate: true,
                handler (val) {
                    if (!val) {
                        this.$emit('closeNotification', this.notification);
                    }
                }
            }
        },

        methods: {

            closeNotification() {
                this.notification = false;
            }
        }
    };
</script>

import _ from 'lodash';
const contexts = new WeakMap();
import { Logger } from '@/services/logger.service';

export default new class NotificationService {

  /**
   * Notification Service Constructor
   */
  constructor() {
    contexts.set(this, {
      notify: {
        visible: false,
        message: '',
        type: 'snackbar'
      }
    });
  }

  /**
   * Returns notify instance
   * @returns {((value: any) => void) | {visible: boolean, message: string} | {handler(*): void, immediate: boolean} | ((typedArray: Int32Array, index: number, count: number) => number)}
   */
  get notify() {
    return contexts.get(this).notify;
  }

  /**
   * Hides Notification
   */
  hide() {
    contexts.get(this).notify.visible = false;
    contexts.get(this).notify.message = '';
  }

  /**
   * Shows Notification
   * @param msg
   * @param type
   */
  show(msg = '', type = 'snackbar') {
    let transformedMsg = msg;
    if (
      type === 'dialog'
      && !_.isObject(msg)
      && (
        _.isUndefined(msg.title)
        || _.isUndefined(msg.text)
      )
    ) {
      Logger('error', 'Notification Service').log('First parameter should be an object, containing title and text properties.')();
      transformedMsg = {
        title: 'Error',
        text: 'Some Text'
      };
    }
    contexts.get(this).notify.message = transformedMsg;
    contexts.get(this).notify.type = type;
    contexts.get(this).notify.visible = true;
  }
}();


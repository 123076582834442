<template>
    <v-dialog
        v-model="showModal"
        width="350"
        height="350"
    >
        <template v-slot:activator="{ openModal }" >
            <v-btn
                class="ma-2"
                color="#F06292"
                filled
                @click="showModal = !showModal"
                v-on="openModal"
            >Forgot Password</v-btn>
        </template>

        <v-card  class="forgot-password-dialog__content">
            <v-card-title
                class="headline indigo lighten-1"
                primary-title
            >Reset Password</v-card-title>

            <v-form @submit.prevent="resetPassword">
                <v-container>
                    <v-row>
                        <v-col cols="10">
                            <v-text-field
                                v-model="email"
                                :rules="[rules.required, rules.email]"
                                solo
                                label="E-mail"
                                light
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn
                            type="submit"
                            class="ma-2"
                            color="#F06292"
                            outlined
                        >Reset Password</v-btn>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

    name: 'ForgotPassword',

    data: () => ({
        showModal: false,
        email: '',
        rules: {
            required: value => !!value || 'Required',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || 'Invalid e-mail';
            }
        }
    }),

    methods: {

        resetPassword() {
            this.$store.dispatch('auth/forgotPassword', {
                email: this.email
            }).then(() => {
                this.email = '';
                this.showModal = false;
            }).catch(() => {});
        }
    }
}
</script>

<style scoped lang="scss">
    .forgot-password-dialog__content {
        background-color: #FFFFFF !important;

        .v-card__title.headline {
            color: #FFFFFF;
        }

        .v-form .row{
            justify-content: center;
        }
    }
</style>

import Rest from '@/services/rest.service';
import _ from 'lodash';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  paginator: {
    current_page: 0,
    total_page: 0
  },
  data: [],
  searchedData: [],
  types: []
};

// Getters
const getters = {

  /**
   * Returns all creatives
   * @param state
   * @returns {*}
   */
  data: state => {
    return state.data;
  },

  /**
   * Returns searched data
   * @param state
   * @returns {any}
   */
  searchedData: state => {
    return state.searchedData;
  },

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => {
    return state.status;
  },

  /**
   * Returns pagination
   * @param state
   * @returns {*}
   */
  pagination: state => {
    return state.pagination;
  },

  /**
   * Returns creatives types
   * @param state
   * @returns {*}
   */
  types: state => {
    return state.types;
  }
};

/**
 * Returns boolean to know if we can get
 * more pages
 * @param paginator
 * @returns {boolean}
 * @private
 */
function _canGetMore(paginator) {

  return (paginator.current_page < paginator.total_pages);
}

// Actions
const actions = {

  /**
   * Returns paginated creatives
   * @param commit
   * @param state
   * @returns {Promise<unknown>}
   */
  get({commit, state}) {

    return new Promise((resolve, reject) => {
      let nextPage = null;
      commit('CREATIVES_REQUEST', true);

      if (_.isUndefined(state.paginator.next_page_url)) {
        nextPage = 1;
      } else if (!_.isNull(state.paginator.next_page_url)) {
        nextPage = state.paginator.current_page + 1;
      }

      if (nextPage === null) {
        commit('CREATIVES_REQUEST', false);
        resolve(_canGetMore(state.paginator));
        return;
      }

      Rest.get('creatives?page=' + nextPage)
        .then(response => {
          commit('SET_PAGINATOR', response.paginator);
          commit('SET_CREATIVES', response.data);
          commit('CREATIVES_REQUEST', false);
          Logger('success', 'Creatives').log('Data retrieved')();
          resolve(_canGetMore(response.paginator));
        }).catch(error => {
          commit('CREATIVES_REQUEST', false);
          Logger('error', 'Creatives').log(error)();
          reject(error);
        });
    });
  },

  /**
   * Searches for a creative
   * @param commit
   * @param keyword
   * @returns {Promise<unknown>}
   */
  search({commit}, keyword) {

    return new Promise((resolve, reject) => {
      commit('CREATIVES_REQUEST', true);

      Rest.get('creatives/search?keyword=' + keyword)
        .then(response => {
          commit('CREATIVES_REQUEST', false);
          commit('SET_SEARCHED_CREATIVES', response.data);
          Logger('success', 'Creatives').log('Search:', response.data)();
          resolve(response);
        }).catch(error => {
          commit('CREATIVES_REQUEST', false);
          Logger('error', 'Creatives').log('Error: ', error)();
          reject(error);
      });
    });
  },

  /**
   * Searches for a creative
   * @param commit
   * @param creativeID
   * @returns {Promise<unknown>}
   */
  delete({commit}, creativeID) {
    return new Promise((resolve, reject) => {
      commit('CREATIVES_REQUEST', true);

      Rest.delete('creatives/'+creativeID).then(response => {
        commit('CREATIVES_REQUEST', false);
        Logger('success', 'Creatives').log('Deleted:', response.data)();
        resolve(response);
      }).catch(error => {
        commit('CREATIVES_REQUEST', false);
        Logger('error', 'Creatives').log('Error: ', error)();
        reject(error);
      });
    });
  },

  /**
   * Gets and sets the current user creative types available.
   * @param commit
   * @param userRole
   */
  getTypes({commit}, userRole) {
    // const roleCapitalized = userRole.name[0].toUpperCase() + userRole.name.slice(1);
    commit('CREATIVES_REQUEST', true);
    Rest.get('creatives/types/' + userRole)
    .then(response => {
      commit('CREATIVES_REQUEST', false);
      commit('SET_CREATIVE_TYPES', response.data);
      Logger('Success', 'Creatives').log('Creative Types set correctly.', response.data)();
    })
    .catch(error => {
      commit('CREATIVES_REQUEST', false);
      Logger('Failed', 'Creatives').log('Failed to get creative types.', error)();
    });
  }
};

// mutations
const mutations = {

  CREATIVES_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_PAGINATOR(state, paginator) {
    state.paginator = paginator;
  },

  SET_CREATIVES(state, creatives) {
    _.each(creatives, creative => {
      state.data.push(creative);
    });
  },

  SET_CREATIVE_TYPES(state, creativesList) {
    state.types = creativesList;
  },

  SET_SEARCHED_CREATIVES(state, creatives) {
    state.searchedData = creatives;
  },

  RESET(state) {
    state.status = '';
    state.paginator = {
      current_page: 0,
      total_page: 0
    };
    state.data = [];
    state.searchedData = [];
    state.types = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

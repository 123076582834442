import Rest from '@/services/rest.service';
import { Logger } from '@/services/logger.service';

// initial state
const state = {
  status: '',
  data: []
};

// Getters
const getters = {

  /**
   * Returns all roles
   * @param state
   * @returns {*}
   */
  data: state => {
    return state.data;
  },

  /**
   * Returns current request status
   * @param state
   * @returns {*}
   */
  status: state => {
    return state.status;
  }
};

// Actions
const actions = {

  /**
   * Returns Roles
   * @param commit
   * @returns {Promise<unknown>}
   */
  get({commit}) {

    return new Promise((resolve, reject) => {
      commit('ROLES_REQUEST', true);

      Rest.get('users/c/roles')
        .then(response => {
          commit('SET_ROLES', response.data);
          commit('ROLES_REQUEST', false);
          Logger('success', 'Roles').log('Data retrieved')();
          resolve(response.data);
        }).catch(error => {
        commit('ROLES_REQUEST', false);
        Logger('error', 'Roles').log(error)();
        reject(error);
      });
    });
  }
};

// mutations
const mutations = {

  ROLES_REQUEST(state, request) {
    state.status = request ? 'loading' : '';
  },

  SET_ROLES(state, roles) {
    state.data = roles;
  },

  RESET(state) {
    state.status = '';
    state.data = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
    <v-dialog
        v-model="dialog"
        value="true"
        persistent
        max-width="512px"
    >
        <template v-slot:activator="{ openModal }" >
            <v-btn
                icon
                @click="dialog = !dialog"
                v-on="openModal"
            >
                <v-icon>fa-plus-circle</v-icon>
            </v-btn>
        </template>

        <v-card v-if="roles.length > 0">
            <v-card-title>
                <span class="headline">New User</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                label="Username*"
                                v-model="user.username"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Email*"
                                v-model="user.email"
                                required
                                outlined
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-select
                                label="Role*"
                                :items="items"
                                v-model="user.role"
                                required
                                outlined
                                v-on:change="changeRole"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-container>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" text @click="dialog = false">Close</v-btn>
                <v-btn color="accent" text @click="save" :loading="isSaving">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import _ from 'lodash';

    export default {

        name: 'NewUser',

        data: () => ({
            dialog: false,
            user: {
                username: '',
                email: '',
                role_id: null,
                role: ''
            },
            roles: [],
            items: [],
            isSaving: false
        }),

        mounted() {
            this.$store.dispatch('roles/get').then(roles => {
                _.each(roles, role => {
                    this.items.push(role.name);
                });
                this.roles = roles;
            });
        },

        methods: {

            changeRole(newVal) {
                const role = _.find(this.roles, {name: newVal});
                if (!_.isNull(role)) {
                    this.user.role_id = role.id;
                }
            },

            save() {
                const toSave = _.cloneDeep(this.user);
                delete toSave.role;
                this.isSaving = true;
                this.$store.dispatch('users/new', toSave).then(data => {
                    this.$store.dispatch('users/single', data.id).then(() => {
                        this.isSaving = false;
                        this.dialog = false;
                        this.user = {
                            username: '',
                            email: '',
                            role_id: null,
                            role: ''
                        };
                    }).catch(() => {});
                }).catch(() => {});
            }
        }
    };
</script>

<template>
    <v-toolbar
        color="primary"
        flat
        :class="{
            'main-toolbar': true,
            'main-toolbar--with-drawer': drawer,
            'main-toolbar--without-drawer': !drawer
        }"
    >
        <v-container>
            <v-row align="center">
                <v-toolbar-title>{{ currentRouteName }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-col
                    cols="5" lg="4" xl="4" md="5"
                    v-if="currentRouteName === 'Dashboard' || currentRouteName === 'Users'"
                >
                    <v-text-field
                        class="mt-sm-8 mt-md-9 mt-7"
                        v-model="keywords"
                        @keyup="() => {}"
                        label="Search"
                        append-icon="fa-search"
                        solo
                    ></v-text-field>
                </v-col>
                <v-col
                    cols="3" lg="4" xl="4"
                    class="text-right"
                    v-if="currentRouteName === 'Dashboard' || currentRouteName === 'Users' || currentRouteName.indexOf('Social Mosaic') > -1"
                >
                    <f-new-creative-menu
                        v-if="currentRouteName === 'Dashboard'"
                    ></f-new-creative-menu>
                    <f-new-user
                        v-if="currentRouteName === 'Users'"
                    ></f-new-user>
                    <f-social-mosaic-menu
                        v-if="currentRouteName.indexOf('Social Mosaic') > -1"
                    ></f-social-mosaic-menu>
                </v-col>
            </v-row>
        </v-container>

        <f-loading
            v-if="loading.visible"
            :type="loading.type"
        ></f-loading>
    </v-toolbar>
</template>

<script>
    import Loading from '@/components/Loading.vue';
    import NewUser from '@/views/users/components/NewUser.vue';
    import NewCreativeMenu from '@/views/creatives/components/NewCreativeMenu.vue';
    import SocialMosaicMenu from '@/views/creatives/socialmosaic/components/SocialMosaicMenu.vue';

    export default {

        name: 'ToolBar',

        props: ['currentRouteName', 'loading', 'drawer'],

        data: () => ({
            keywords: ''
        }),

        watch: {

            keywords(val) {
                this.$emit('keywords', val);
            }
        },

        components: {
            FNewUser: NewUser,
            FNewCreativeMenu: NewCreativeMenu,
            FSocialMosaicMenu: SocialMosaicMenu,
            FLoading: Loading
        }
    };
</script>
<style lang="scss">
    .main-toolbar {
        position: fixed;
        top: 64px;
        width: 100%;
        z-index: 1;

        @media only screen and (min-width: 600px) and (max-width: 1263px) {
            top: 56px;
        }

        &--without-drawer {
            width: 100%;
        }

        &--with-drawer {
            width: 100%;

            @media only screen and (min-width: 1300px) {
                width: calc(100% - 250px);
            }
        }
    }
</style>
